import PropTypes from "prop-types"
import React from "react"
import styles from "./banner.module.scss"
import logo from "../../images/headwind-logo.svg"
import Image from "../image"
import { Parallax } from "react-scroll-parallax"
import classnames from "classnames"

const Banner = props => {
  const classNames = classnames(styles.banner, props.className, {
    [styles.greyTop]: props.greyTop,
    [styles.greyBot]: props.greyBot,
    [styles.overlay]: props.overlay,
    [styles.hasImage]: (props.img || props.desktopImg),
  });
  const imgClassNames = classnames(styles.image, props.imageClass, {
    [styles.desktopImg]: props.desktopImg
  });
  const showTitle = props.title || props.logo || props.subtitle

  return (
    <div className={classNames}>
      <div className={styles.inner}>
        <div className={styles.container}>
          <div className={styles.content}>
            {showTitle && (
              <div className={styles.title}>
                {props.logo && (
                  <img className={styles.logo} src={logo} alt="Headwind" />
                )}
                {props.title && (
                  <h1>
                    {props.title}
                    {props.subtitle && <span>{props.subtitle}</span>}
                  </h1>
                )}
              </div>
            )}
            {props.children}
          </div>
          {props.img && (
            <div className={imgClassNames}>
              <Image src={props.img} alt={props.title} />
              {props.desktopImg && <Image src={props.desktopImg} alt={props.title} />}
            </div>
          )}
        </div>
      </div>
      {props.bg && (
        <Parallax className={styles.parallax} y={[-20, 20]}>
          <div className={styles.bgImage}>
            <Image src={props.bg} />
          </div>
        </Parallax>
      )}
    </div>
  )
}

Banner.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  img: PropTypes.string,
  bg: PropTypes.string,
  logo: PropTypes.bool,
  grey: PropTypes.bool,
}

Banner.defaultProps = {
  title: ``,
  subtitle: ``,
  bg: ``,
  img: ``,
  logo: false,
  grey: false,
}

export default Banner
