import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import styles from "./section.module.scss"
import LayeredImage from "../layered-image"
import Image from "../image"
import posed from "react-pose"
import classnames from "classnames"
import inView from "in-view"
import { hashString } from "../../utils"
import { Parallax } from "react-scroll-parallax"

const InnerSect = posed.div({
  visible: {
    opacity: 1,
    scale: 1,
    y: 0,
  },
  hidden: {
    opacity: 0,
    scale: 0.5,
    y: "200px",
  },
})

const Section = props => {
  const cls = `section_${hashString(props.title)}`
  const classNames = classnames(styles.section, cls, {
    [styles.hasImage]: props.img,
    [styles.desktopImg]: props.desktopImg,
    [styles.grey]: props.grey,
    [styles.layered]: Array.isArray(props.img),
    [styles.flip]: props.flip,
  })

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    inView.threshold(0.5)
    inView(`.${cls}`).once("enter", () => {
      setVisible(true)
    })
  }, [])

  return (
    <section className={classNames}>
      <InnerSect className={styles.inner} pose={visible ? "visible" : "hidden"}>
        {props.img && (
          <div className={styles.image}>
            {Array.isArray(props.img) && (
              <LayeredImage src={props.img} alt={props.title} reversed={props.reversed} />
            )}
            {!Array.isArray(props.img) && (
              <>
                <Image src={props.img} alt={props.title} />
                {props.desktopImg && <Image src={props.desktopImg} alt={props.title} />}
              </>
            )}
          </div>
        )}
        <div className={styles.text}>
          <h2 className={styles.title}>{props.title}</h2>
          {props.children}
        </div>
      </InnerSect>
      {props.decor && (
        <div className={styles.parallax}>
          <Parallax y={[20, -20]} />
        </div>
      )}
    </section>
  )
}

Section.propTypes = {
  title: PropTypes.string,
  img: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  grey: PropTypes.bool,
  decor: PropTypes.bool,
}

Section.defaultProps = {
  title: ``,
  img: ``,
  grey: false,
  decor: false,
}

export default Section
