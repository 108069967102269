import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import styles from "./layered-image.module.scss"
import Image from "../image"
import posed from "react-pose"
import classnames from "classnames"
import inView from "in-view"
import { hashString } from "../../utils"

const Holder = posed.div({
  threeDee: {
    rotateX: "60deg",
    rotateY: "0deg",
    rotateZ: ({ reversed }) => (reversed ? "45deg" : "-45deg"),
    transition: {
      delay: 1000,
    },
  },
  flat: {
    rotateX: "0deg",
    rotateY: "0deg",
    rotateZ: ({ reversed }) => (reversed ? "90deg" : "-90deg"),
  },
})

const Pane = posed.div({
  active: {
    x: ({ i, reversed }) => {
      if (reversed) {
        if (i === 0) {
          return "100px"
        } else if (i === 2) {
          return "-100px"
        } else {
          return "0px"
        }
      } else {
        if (i === 0) {
          return "-100px"
        } else if (i === 2) {
          return "100px"
        } else {
          return "0px"
        }
      }
    },
    y: ({ i, reversed }) => {
      if (reversed) {
        if (i === 0) {
          return "100px"
        } else if (i === 2) {
          return "-100px"
        } else {
          return "0px"
        }
      } else {
        if (i === 0) {
          return "100px"
        } else if (i === 2) {
          return "-100px"
        } else {
          return "0px"
        }
      }
    },
    transition: {
      delay: 1500,
      type: "spring",
      stiffness: "100",
    },
  },
  inactive: {
    x: "0",
    y: "0",
  },
})

const LayeredImage = props => {
  const cls = `section_${hashString(props.src.join())}`
  const classNames = classnames(styles.holder, cls)

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    inView(`.${cls}`).once("enter", () => {
      setVisible(true)
    })
  }, [])

  return (
    <Holder
      className={classNames}
      pose={visible ? "threeDee" : "flat"}
      reversed={props.reversed}
    >
      {props.src.map((src, i) => {
        return (
          <Pane
            key={i}
            className={styles.image}
            pose={visible ? "active" : "inactive"}
            i={i}
            reversed={props.reversed}
          >
            {typeof src === "string" && (
              <Image src={src} alt={`${props.alt} ${i + 1}`} />
            )}
            {typeof src !== "string" && src}
          </Pane>
        )
      })}
    </Holder>
  )
}

LayeredImage.propTypes = {
  src: PropTypes.array,
  alt: PropTypes.string,
}

LayeredImage.defaultProps = {
  src: [],
  alt: "",
}

export default LayeredImage
