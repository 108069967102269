import React from 'react';
import styles from './footer.module.scss';
import poweredByStrava from '../../images/powered-by-strava.svg';

const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.footerLeft}>
      &copy; Headwind | <a href="https://headwind.app/privacy" target="_blank" rel="noopener noreferrer">Privacy</a>
    </div>
    <div className={styles.footerRight}>
      <img src={poweredByStrava} width="150" alt="Powered by Strava" />
    </div>
  </footer>
);

export default Footer;