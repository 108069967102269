import React from "react"
import Layout from "../components/layout"
import LogoGrid from "../components/logo-grid"
import SEO from "../components/seo"
import Banner from "../components/banner"
import Section from "../components/section"
import Button from "../components/button"
import WindMap from "../components/wind-map"
import styles from "../style-utils/index.module.scss"
import { trackExternalButtonClick } from "../utils"
import rainRadarGif from "../images/headwindapp-ui-layer-radar.gif"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Headwind App" />

      <Banner
        title="Headwind"
        subtitle="Ride like the wind"
        bg="headwindapp-lone-cyclist.jpg"
        img="headwindapp-intro-mobile.png"
        desktopImg="headwindapp-intro-desktop.png"
        logo
        overlay
        imageClass={styles.introImage}
      >
        <p>
          Connect with Strava to visualise how hard your latest rides were, and
          predict how hard your future rides will be, based on real time
          hyperlocal weather information.
        </p>
        <div className={`${styles.buttons} ${styles.buttonsHasImg}`}>
          <Button
            outline
            bold
            className={styles.button}
            onClick={trackExternalButtonClick.bind(
              null,
              "Clicked Demo link in Top Banner",
              "https://headwind.app/demo"
            )}
          >
            View demo
          </Button>
          <Button
            outline
            bold
            className={styles.button}
            onClick={trackExternalButtonClick.bind(
              null,
              "Clicked launch link in top banner",
              "https://headwind.app"
            )}
          >
            Launch App
          </Button>
        </div>
      </Banner>

      <Section
        title="Utilizes Dark Sky for hyperlocal weather reporting"
        decor
        reversed
        img={[
          "headwindapp-ui-layer-map.png",
          "headwindapp-ui-layer-chrome.png",
          <WindMap
            windSpeed={30}
            windDir={20}
            height="469px"
            width="840px"
            className={styles.windMap}
          />,
        ]}
      >
        <p>
          Headwind grabs the latest weather information for the exact latitude
          and longitude of the start of your ride and then uses that
          information, coupled with the data from Strava to give you a
          difficulty score out of 5 for the ride.
        </p>
      </Section>

      <Section
        grey
        title="Creates in depth ride and route heatmaps"
        img="headwindapp-heatmap.png"
        flip
      >
        <p>
          Hyperlocal wind direction and wind speed data is used to draw your
          Strava ride or saved route onto a mapbox map.
        </p>
        <p>
          The route colour is changed based on whether you are headed into the
          wind or away from the wind which makes it incredibly easy to quickly
          visualise how hard your ride will be and what sections of the ride
          will be a challenge.
        </p>
      </Section>

      <Banner bg="headwindapp-retro-cyclists.jpg" greyTop overlay />

      <Section
        title="Past, present, and future difficulty ratings"
        decor
        img="headwindapp-past-present-future-mobile.png"
        desktopImg="headwindapp-past-present-future-desktop.png"
      >
        <p>
          You can choose to look back at how hard your ride was, use it to
          calculate how hard your ride will be right now, or use it to predict
          how hard your ride will be up to 7 days into the future. Never get
          caught battling a headwind again.
        </p>
      </Section>

      <Section
        grey
        title="Learn how many calories you burn cycling directly into a headwind"
        img="headwindapp-calorie-counter.png"
        flip
      >
        <p>
          Ever wondered how many more calories you are burning by cycling
          directly into a headwind?
        </p>
        <p>
          Headwind will take the wind into account whilst calculating calories
          burnt during a ride, meaning you can see exactly how many calories you
          are burning at any given time.
        </p>
      </Section>

      <Banner bg="headwindapp-cyclists-train.jpg" greyTop overlay />

      <Section
        title="Current Rain Radar for your precise location"
        decor
        reversed
        img={[
          "headwindapp-ui-layer-map.png",
          <div className={styles.rainRadarGif}>
            <img src={rainRadarGif} alt="Headwind App Rain Radar Animation" />
          </div>,
          "headwindapp-ui-layer-chrome.png",
        ]}
      >
        <p>
          Headwind utalizes{" "}
          <a href="https://www.rainviewer.com/">Rain Viewer</a> to overlay
          current rain radar for your precise location on top of your ride
          heatmap.
        </p>
        <p>Wind Visualisation plus Rain Radar equals the perfect ride home.</p>
      </Section>

      <Section
        grey
        title="Built with the commuter in mind"
        decor
        img="headwindapp-cycle-commute.png"
      >
        <p>
          Headwind also includes a forecast view which will show you the weather
          and difficulty score of a particular ride or route for the next 7
          days. This is particularly good for picking which days are best to
          commute to and from work.
        </p>
      </Section>

      <Section title="Powered by">
        <p>
          Headwind connects with Strava to sync all your latest rides, fetches
          hyperlocal weather data from the Darksky weather API, utilzes Rain
          Viewers rain radar API, and runs on Amazons blazing fast AWS Service.
        </p>
        <LogoGrid
          logos={[
            "powered-by-strava.png",
            "powered-by-darksky.png",
            "powered-by-rain-viewer.png",
            "powered-by-aws.png",
          ]}
        />
      </Section>

      <Banner
        overlay
        title="Heard Enough?"
        bg="headwindapp-tired-cyclists.jpg"
        className={styles.bottomBanner}
      >
        <p>
          Hit the ‘get started’ button below and connect headwind to your Strava
          account and get cycling.
        </p>
        <p>
          Haven't got a strava account or want to see what headwind is all about
          before committing? Hit the ‘view demo’ button to be logged into a demo
          Headwind account and have a play around.
        </p>
        <div className={styles.buttons}>
          <Button
            outline
            bold
            className={styles.button}
            onClick={trackExternalButtonClick.bind(
              null,
              "Clicked Demo link in Bottom Banner",
              "https://headwind.app/demo"
            )}
          >
            View demo
          </Button>
          <Button
            outline
            bold
            className={styles.button}
            onClick={trackExternalButtonClick.bind(
              null,
              "Clicked launch link in bottom banner",
              "https://headwind.app"
            )}
          >
            Launch App
          </Button>
        </div>
      </Banner>
    </Layout>
  )
}

export default IndexPage
