import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styles from "./header.module.scss"
import logo from "../../images/headwind-logo-gradient.svg"
import Button from "../button"
import { trackExternalButtonClick } from "../../utils"

const Header = ({ title }) => (
  <header className={styles.header}>
    <h1 className={styles.title}>
      <Link to="/">
        <img src={logo} alt="Headwind Logo" />
      </Link>
      {title}
    </h1>
    <div
      className={`fb-like ${styles.facebookButton}`}
      data-href="https://headwindapp.com"
      data-width=""
      data-layout="button_count"
      data-action="like"
      data-size="large"
      data-share="true"
    />
    <Button
      slim
      className={styles.action}
      onClick={trackExternalButtonClick.bind(
        null,
        "Clicked Demo link in Header",
        "https://headwind.app/demo"
      )}
    >
      View Demo
    </Button>
    <Button
      slim
      primary
      className={styles.action}
      onClick={trackExternalButtonClick.bind(
        null,
        "Clicked launch link in Header",
        "https://headwind.app"
      )}
    >
      Launch App
    </Button>
  </header>
)

Header.propTypes = {
  title: PropTypes.string,
}

Header.defaultProps = {
  title: ``,
}

export default Header
