import React, { Component } from 'react';
import distance from '@turf/distance';
import destination from '@turf/destination';
import styles from './wind-map.module.scss';
import inView from 'in-view';


class WindMap extends Component {

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.particleColor = this.props.color || '#ffffff';
    this.particleMinSize = .1;
    this.particleMaxSize = .5;
    this.particleSpeed = this.props.windSpeed ? this.props.windSpeed / 16 : 0;
    this.particleNo = this.props.windSpeed * 50;
    this.particles = [];
    this.particleTrailLength = this.particleSpeed * 50;
    this.particleRGB = this.hex2Rgb(this.particleColor);
    this.particleDir = (this.props.windDir || 0) + 180;
  }

  componentDidMount() {
    this.pxRatio = Math.max(Math.floor(window.devicePixelRatio) || 1, 2);
    this.canvas = this.canvasRef.current;
    this.ctx = this.canvas.getContext('2d');

    if (this.props.width) {
      this.canvas.style.width = this.props.width;
    }
    if (this.props.height) {
      this.canvas.style.height = this.props.height;
    }

    const width = (this.canvas.clientWidth * this.pxRatio);
    const height = (this.canvas.clientHeight * this.pxRatio);
    this.canvas.width = width;
    this.canvas.height = height;

    this.particleBoundries = {
      top: -(this.canvas.height / 3),
      bottom: this.canvas.height + (this.canvas.height / 3),
      left: -(this.canvas.width / 3),
      right: this.canvas.width + (this.canvas.width / 3)
    };

    this.spawnParticles();
    requestAnimationFrame(this.update);

    if (this.props.bounds && this.props.map) {
      this.addWindToMap();
    }

  }

  componentWillUnmount() {
    if (this.props.map) {
      this.props.map.removeLayer('canvas');
      this.props.map.removeSource('canvas');
    }
    if (this.animationFrameID) {
      window.cancelAnimationFrame(this.animationFrameID);
    }
  }

  render() {
    return(
      <div className={this.props.className}>
        <canvas className={styles.canvas} id="windMap" ref={this.canvasRef} />
      </div>
    );
  }

  getBoundDimensions(bounds) {
    // calculate the the width and height of the bounds
    const top = [bounds.getNorthWest().lng, bounds.getNorthWest().lat];
    const bot = [bounds.getSouthWest().lng, bounds.getSouthWest().lat];
    const left = [bounds.getSouthEast().lng, bounds.getSouthEast().lat];
    const right = [bounds.getSouthWest().lng, bounds.getSouthWest().lat];
    return {
      height: distance(top, bot),
      width: distance(left, right)
    }
  }

  createSquareBounds(bounds, padding) {
    const size = this.getBoundDimensions(bounds);
    let coordinates;
    let nw;
    let ne;
    let se;
    let sw;

    const center = bounds.getCenter();
    const dist = Math.max(size.width, size.height);
    const rad = (Math.sqrt(Math.pow(dist, 2) + Math.pow(dist, 2)) / 2) + padding;

    // make the bounds a square and add padding
    nw = destination([center.lng, center.lat], rad, 315);
    ne = destination([center.lng, center.lat], rad, 45);
    se = destination([center.lng, center.lat], rad, 135);
    sw = destination([center.lng, center.lat], rad, 225);

    // create the coordinates
    coordinates = [
      [nw.geometry.coordinates[0], nw.geometry.coordinates[1]],
      [ne.geometry.coordinates[0], ne.geometry.coordinates[1]],
      [se.geometry.coordinates[0], se.geometry.coordinates[1]],
      [sw.geometry.coordinates[0], sw.geometry.coordinates[1]],
    ];

    return coordinates;
  }

  addWindToMap() {
    const windBounds = this.props.bounds;
    const coordinates = this.createSquareBounds(windBounds, 3);
    this.props.map.addSource('canvas', {
      type: 'canvas',
      canvas: 'windMap',
      animate: true,
      coordinates,
    });
    this.props.map.addLayer({
      id: 'canvas',
      source: 'canvas',
      type: 'raster',
      paint: {
        'raster-opacity': 1
      }
    });
  }

  update = () => {
    if (inView.is(this.canvas)) {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.updateParticles();
      this.drawParticles();
      this.circleClip();
    }
    this.animationFrameID = requestAnimationFrame(this.update);
  }

  spawnParticles = () => {
    for (let i=0; i< this.particleNo; i++) {
      this.particles.push(this.spawnParticle());
    }
  }

  spawnParticle = () => {
    let speed =  this.randomBetween(this.particleSpeed - .2, this.particleSpeed + .2, 1) || this.particleSpeed;
    if (speed <= 0) {
      speed = 0.1;
    }
    const x = this.randomBetween(this.particleBoundries.left, this.particleBoundries.right);
    const y = this.randomBetween(this.particleBoundries.top, this.particleBoundries.bottom);
    return {
      x,
      y,
      size: this.randomBetween(this.particleMinSize, this.particleMaxSize),
      idealAlpha: this.randomBetween(.2, 1, 1),
      alpha: 0,
      speed,
      trail: {
        length: {x: 0, y: 0},
        start: {x, y},
        end: {x, y}
      }
    };
  }

  updateParticles = () => {
    this.particles.forEach((particle, i) => {
      this.updateParticle(particle, i);
    });
  }

  updateParticle = (particle, i) => {
    const vector = this.vector(particle.speed, this.particleDir);
    particle.x += vector.x;
    particle.y += vector.y;
    if (particle.alpha < particle.idealAlpha) {
      particle.alpha += 0.01;
    }
    const trailLength = this.vector(this.particleTrailLength, this.particleDir);
    particle.trail = {
      start: {
        x: particle.x,
        y: particle.y
      },
      end: {
        x: particle.x - trailLength.x,
        y: particle.y - trailLength.y
      }
    }
    if (
      particle.trail.end.x >= this.particleBoundries.right ||
      particle.trail.end.x <= this.particleBoundries.left ||
      particle.trail.end.y >= this.particleBoundries.bottom ||
      particle.trail.end.y <= this.particleBoundries.top
    ) {
      this.particles[i] = {...this.spawnParticle()};
    }
  }

  drawParticles = () => {
    this.particles.forEach((particle) => {
      this.drawParticle(particle);
    });
  }

  drawParticle = (particle) => {
    this.drawParticleTrail(particle);
    const color = this.particleRGB;
    this.ctx.beginPath();
    this.ctx.fillStyle = `rgba(${color.r}, ${color.g}, ${color.b}, ${particle.alpha})`;
    this.ctx.arc(particle.x, particle.y, particle.size, 0, Math.PI * 2, true);
    this.ctx.fill();
    this.ctx.closePath();
    this.ctx.closePath();
  }

  drawParticleTrail = (particle) => {
    const color = this.particleRGB;
    const trail = particle.trail;
    const gradient = this.ctx.createLinearGradient(trail.start.x, trail.start.y, trail.end.x, trail.end.y);
    gradient.addColorStop(0, `rgba(${color.r}, ${color.g}, ${color.b}, ${particle.alpha})`);
    gradient.addColorStop(1, `rgba(${color.r}, ${color.g}, ${color.b}, 0`);
    this.ctx.strokeStyle = gradient;
    this.ctx.lineWidth = particle.size * 2;
    this.ctx.beginPath();
    this.ctx.moveTo(trail.start.x, trail.start.y);
    this.ctx.lineTo(trail.end.x, trail.end.y);
    this.ctx.stroke();
    this.ctx.closePath();
  }

  circleClip = () => {
    this.ctx.globalCompositeOperation = 'destination-in';
    this.ctx.save();
    this.ctx.fillStyle = 'white';
    this.ctx.beginPath();
    const circle = {
      x: this.canvas.width / 2,
      y: this.canvas.height / 2,
      radius: Math.min(this.canvas.width, this.canvas.height) / 2
    }
    this.ctx.arc(circle.x, circle.y, circle.radius, 0, Math.PI * 2, true);
    this.ctx.fill();
    this.ctx.restore();
    this.ctx.globalCompositeOperation = 'source-over';
  }

  vector(distance, angle){
    const angleRadians = ((angle + 270) * Math.PI) / 180;
    return {
      x: distance * Math.cos(angleRadians),
      y: distance * Math.sin(angleRadians)
    }
  }

  randomBetween = (min, max, decimalPlaces = 0) => {
    const rand = Math.random() * (max - min) + min;
    const power = Math.pow(10, decimalPlaces);
    return Math.floor(rand * power) / power;
  }

  hex2Rgb(hex, opacity) {
    hex = hex.replace('#', '');
    return {
      r: parseInt(hex.substring(0,2), 16),
      g: parseInt(hex.substring(2,4), 16),
      b: parseInt(hex.substring(4,6), 16)
    };
  }

}

export default WindMap;