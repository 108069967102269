import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Header from "../header"
import Footer from "../footer"
import "../../style-utils/global.scss"
import { ParallaxProvider } from "react-scroll-parallax"
import Helmet from "react-helmet"
import OGImage from "../../images/headwind-og-image.png"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <ParallaxProvider>
        <Helmet>
          <meta
            name="google-site-verification"
            content="uQL9WPrugHrOL5wwtcsKW-nLVm3cWiDdU0RbGpvT_18"
          />
          <meta property="og:image" content={OGImage} />
          <script
            async
            defer
            crossorigin="anonymous"
            src="https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v6.0"
          />
        </Helmet>
        <div className="app">
          <Header title="Headwind" />
          <main>{children}</main>
          <Footer />
        </div>
      </ParallaxProvider>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
