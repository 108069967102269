
import React from 'react';
import styles from './button.module.scss';

const Button = (props) => {
  const className = props.className ? props.className : '';
  const state = styles[props.state] ? styles[props.state] : '';
  const primary = props.primary ? styles.primary : '';
  const slim = props.slim ? styles.slim : '';
  const light = props.light ? styles.light : '';
  const full = props.full ? styles.full : '';
  const outline = props.outline ? styles.outline : '';
  const bold = props.bold ? styles.bold : '';
  const onClick = props.onClick ? props.onClick : () => {};
  const classNames = `${className} ${styles.button} ${state} ${primary} ${light} ${full} ${slim} ${outline} ${bold}`;
  return(
    <>
      {props.href &&
        <a className={classNames} href={props.href}>
          <span>
            <span>{props.children}</span>
          </span>
        </a>
      }
      {!props.href &&
        <button onClick={onClick} className={classNames}>
          <span>
            <span>{props.children}</span>
          </span>
        </button>
      }
    </>
  );
}

export default Button;