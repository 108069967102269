import PropTypes from 'prop-types';
import React from 'react';
import styles from './logo-grid.module.scss';
import Image from '../image';

const LogoGrid = (props) => {

  return (
    <ul className={styles.grid}>
      {props.logos.map((logo, i) => {
        return (
          <li className={styles.gridItem} key={i}>
            <Image src={logo} alt={`logo ${i}`} />
          </li>
        )
      })}
    </ul>
  );

};

LogoGrid.propTypes = {
  logos: PropTypes.array
}

LogoGrid.defaultProps = {
  logos: []
}

export default LogoGrid;
